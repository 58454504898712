import React from 'react';

const Follow = ({ username, size = 'large' }) => {
  return (
    <a
      className="w-max flex text-xs justify-center items-center text-white bg-black rounded-full px-3 font-light py-0.5 break-inside-avoid"
      href={`https://twitter.com/intent/follow?region=follow_link&screen_name=${username}`}
      target="_blank"
      rel="noopener noreferrer"
      data-size={size}
    >
      <span className={`flex mr-1 ${size === 'large' ? 'w-5 h-5 my-px' : 'w-3.5 h-3.5'}`}>
        <svg viewBox="0 0 24 24" aria-hidden="true" fill="white">
          <g>
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
          </g>
        </svg>
      </span>
      Follow @{username}
    </a>
  );
};

export default Follow;
