import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Follow from '../components/follow';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { SignupForm } from '../components/signup';
import mail from '../images/icons/mail.svg';

const Join = ({ data, location }) => {
  const isElection = false;
  const DoloresPark = getImage(data.DoloresPark.childImageSharp);
  const hero = getImage(data.hero.childImageSharp);

  return (
    <Layout
      headerOptions={{
        borderless: true,
        blue: true,
      }}
      footerOptions={{
        hideSignup: true,
      }}
    >
      <SEO
        title="Become an informed citizen"
        description="Our heavily-researched voter guide makes it dead simple to help elect results-focused leaders."
      />
      {/* Hero */}
      <section>
        <div className="relative flex flex-col-reverse overflow-hidden -mt-px lg:min-h-[650px] lg:flex-col lg:mt-0">
          <div className="relative inset-0 lg:absolute">
            <div className="absolute z-10 -top-0.5 left-0 w-full h-20 bg-gradient-to-b from-[#DAC9BD] to-transparent lg:hidden"></div>
            <GatsbyImage
              image={hero}
              loading="eager"
              alt="A view of San Francisco from Dolores Park"
              className="z-0 h-full w-full min-h-[250px] -mt-0.5 object-cover sm:mt-0"
            />
          </div>
          <div className="relative w-full flex flex-1 mx-auto pt-3 px-5 bg-gradient-to-b from-brand-blue-3 to-[#DAC9BD] lg:pt-0 lg:px-0 lg:bg-none lg:max-w-7xl">
            {isElection ? (
              <div className="hero-top text-center bg-white m-auto w-full max-w-max lg:max-w-3xl p-4 lg:p-12">
                <h1 className="text-3xl sm:text-4xl lg:text-6xl font-black text-gray-900 tracking-tight">
                  Read our voter guide
                </h1>
                <p className="text-lg text-gray-700 leading-relaxed mb-6">
                  We love SF, but need to fix things
                </p>
                <Link
                  to="/voter-guide"
                  className="bg-brand-blue-5 h-max flex justify-center items-center w-max mx-auto px-5 py-4 uppercase tracking-widest font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid"
                >
                  See the voter Guide
                </Link>
                <div className="w-full flex justify-center xl:hidden mt-3 h-7">
                  <Follow options={{ size: 'large' }} username="GrowSF" />
                </div>
              </div>
            ) : (
              <div className="hero-mask relative mx-auto w-full h-max sm:max-w-lg bg-white px-4 py-6 lg:px-12 lg:-mt-0.5 lg:-ml-0.5 lg:pb-12">
                <div className="px-1">
                  <h1 className="text-3xl text-center lg:text-left sm:text-4xl lg:text-4xl font-black text-gray-900 tracking-tight mb-3">
                    Get the latest news and most informative voter guides
                  </h1>
                  <p className="text-base text-gray-700 leading-normal mb-4">
                    We love San Francisco, but there's so much we need to do to improve public
                    safety, homelessness, and the cost of housing.
                  </p>
                  <p className="text-base text-gray-700 leading-normal mb-4">
                    GrowSF pursues common-sense solutions to create a San Francisco that works for
                    everyone.
                  </p>

                  <p className="text-base text-gray-700 leading-normal mb-4">
                    Our heavily researched voter guides and weekly news roundup makes it simple to
                    help elect leaders that are focused on results, not ideology.
                  </p>

                  <div className="z-10 relative">
                    <h2 className="text-sm font-bold text-brand-blue-5 mb-1.5">Join us:</h2>
                    <SignupForm formStyle="home" formId="_hero" />
                  </div>
                </div>
                <img
                  className="z-0 absolute bottom-28 right-0 lg:bottom-12"
                  height={114}
                  width={114}
                  src={mail}
                  alt="mail"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {/* End Hero */}
    </Layout>
  );
};
export default Join;

export const query = graphql`
  query {
    DoloresPark: file(relativePath: { eq: "core/dolores-park.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    hero: file(relativePath: { eq: "homepage/hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
